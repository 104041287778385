import { baseApiURL } from "../Utilities/Utility";
import axios from "axios";

export function postPOEntryDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {


            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData
    };
    return fetch(baseApiURL + "/POEntry/PostPOEntryDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updatePOEntryDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        headers: {

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData
    };
    return fetch(baseApiURL + "/POEntry/UpdatePOEntryDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function GetAllPoEntryDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/POEntry/GetAllPoEntryDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getProductMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetProductMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getSerialNoYearMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",

        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetUniqueSerialNoYearMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getTechnicalStatusMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetTechnicalStatusMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getCommercialStatusMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetCommercialStatusMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getCustomerDetailsMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, value) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Customer/GetCustomerDetails?EncdUserId=" + encodeURIComponent(userId) + "&value=" + value, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function postPOEntry(siteToken, formData) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        body: formData
    };
    return fetch(baseApiURL + "/POEntry/PostPOEntryDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


export function copyPOEntryDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, yearId, EpPoentryPageId, userID) {
    const params = {
        yearId: yearId,
        EpPoentryPageId: EpPoentryPageId,
        userId: userID,
    }
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },


        body: JSON.stringify(params),
    };
    return fetch(baseApiURL + "/POEntry/CopyPOEntryDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


// export function poEntryAttachmentsBasedonId(siteToken, epPoentryId, userId) {
//     const options = {
//         method: "get",
//         mode: "cors",
//         headers: {
//             Authorization: "Bearer " + siteToken,
//         }
//     };
//     const epID = epPoentryId;
//     const userID = userId;
//     return fetch(baseApiURL + "/POEntry/PoEntryAttachmentsBasedonId?EpPoentryPageId=epID&userId=userID", options)
//         .then((response) => response.json())
//         .then((data) => {
//             return data.result;
//         })
//         .catch((error) => {
//             console.log("Error : ", error);
//         });
// }

//export function poEntryAttachmentsBasedonId(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId, epPoentryId) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/POEntry/PoEntryAttachmentsBasedonId",
//        headers: {
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        },
//        params: { EpPoentryPageId: epPoentryId, EncdUserId: encodeURIComponent(userId)},
//    };
//    return (
//        axios(options)
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );
//}
export function poEntryAttachmentsBasedonId(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, epPoentryId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/POEntry/PoEntryAttachmentsBasedonId?EpPoentryPageId=" + epPoentryId + "&EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deletePOEntryAttachments(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    var formdata = {
        AttachmetId: data.attachmetId,
    };
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata)
    };
    return fetch(baseApiURL + "/POEntry/PODeleteAttachments?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

// export function deletePOEntryAttachments(siteToken, rowData) {
//     const options = {
//         method: "put",
//         url: baseApiURL + "/POEntry/PODeleteAttachments",
//         headers: {
//             Authorization: "Bearer " + siteToken,
//         },
//         params: { data: rowData },
//     };

//     return (axios(options)
//         .then((response) => response.json())
//         .then((data) => {
//             return data.result;
//         })
//         .catch((error) => {
//             console.log("Error : ", error);
//         })
//     );

//     // return (
//     //     axios(options)
//     //         .then((response) => {
//     //             return response;
//     //         })
//     //         .catch((error) => {
//     //             console.log("Error : ", error);
//     //         })
//     // );
// }

export function getReason(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetReasonForOrderWin?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getOldMake(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetOldMakeBreaker?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getNewMake(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetNewBreakerModel?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getCountry(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetUniqueSerialNoCountryMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetSearch(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, productId, yearId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },


    };
    return fetch(baseApiURL + "/POEntry/GetEntryDetailsbasedonProduct?EncdUserId=" + encodeURIComponent(userId) + "&productId=" + productId + "&yearId=" + yearId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getTypeId(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetTypeMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deletePOEntry(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, EpPoentryPageId) {
    var formdata = {
        EpPoentryPageId: EpPoentryPageId,
    };
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata)
    };
    return fetch(baseApiURL + "/POEntry/DeletePOEntryDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetObject(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, PoEntryId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },



    };
    return fetch(baseApiURL + "/POEntry/GetDetailsbasedonEntryId?EncdUserId=" + encodeURIComponent(userId) + "&PoEntryId=" + PoEntryId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getOffer(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetOfferMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getReference(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, offerId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Offer/AutomatedOfferReferenceNumber?offerId=" + offerId + "&EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//export function getOfferTable(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId,offerId,yearId) {
//    const options = {
//        method: "get",
//        headers: {
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        },

//    };
//    return fetch(baseApiURL + "/Offer/GetAllPrepareOfferDetailsBasedOnid?offerId=" + offerId  +  "&EncdUserId=" + encodeURIComponent(userId), options)
//        .then((response) => response.json())
//        .then((data) => {
//            return data.result;
//        })
//        .catch((error) => {
//            console.log("Error : ", error);
//        });
//}

export function getOfferTable(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, offerId, yearId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Offer/GetAllPrepareOfferDetailsBasedOnid?offerId=" + offerId + "&yearid=" + yearId + "&EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function SaveOfferGrid(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/PostPrepareOfferData?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateGrid(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/UpdatePrepareOfferData?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function DelGrid(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/DeletePrepareOfferData?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//prepare offer
export function getPrepOfferTable(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, refId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Offer/GetItemDetailsBasedOnPrepareOrderId?OfferReferenceNumberId=" + refId + "&EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function updateItemGrid(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/UpdateItemDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function SaveItemGrid(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/PostItemDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function DelItemGrid(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/DeleteItemDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getItem(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, offerId, value) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Offer/GetMLFBDataBasedOnOfferId?offerid=" + offerId + "&EncdUserId=" + encodeURIComponent(userId) + "&value=" + value, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getPlace(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Offer/GetAllPlaceOfSupply?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllStatus(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Offer/GetAllStatus?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getAllState(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Offer/GetAllState?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllTax(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Offer/GetAllTaxRates?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getSign(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, total, approval1, approval2, approval3) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Offer/GetUsersBasedOnRollValueAndRole?EncdUserId=" + encodeURIComponent(userId) + "&total=" + total + "&ApprovalStatusL1=" + approval1 + "&ApprovalStatusL2=" + approval2 + "&ApprovalStatusL3=" + approval3, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function updateApproval(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)
    };
    return fetch(baseApiURL + "/Offer/UpdateApprovalPendingWithDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function updateStatus(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

        body: JSON.stringify(formData)
    };
    return fetch(baseApiURL + "/Offer/ApprovalStatus?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getCommercial(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Offer/CommercialDDL?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//export function downloadCertificate(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId, formData) {
//    const options = {
//        method: "post",
//        url: baseApiURL + "/Offer/DownloadOfferReport?EncdUserId="+encodeURIComponent(userId),
//        responseType: "arraybuffer",
//        headers: {
//            Accept: "application/json, text/plain, */*",
//            "Content-Type": "application/json",

//            Authorization: "Bearer " + siteToken.token,
//            UserEmail: userEmail,
//            Gid: gid,
//            Roleid: roleId,
//            AzureToken: azureToken,
//            RoleName: roleName,
//        },
//        body: JSON.stringify(formData),
//    };
//    return axios(options)
//        .then((response) => {
//            return response;
//            console.log(response);
//        })
//        .catch((error) => {
//            return error;
//        });

//}

export function downloadCertificate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData) {
    const options = {
        method: "post",
        responseType: "arraybuffer",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

        body: JSON.stringify(formData)
    };
    return fetch(baseApiURL + "/Offer/DownloadOfferReport?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getContent(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Offer/GetReportContent?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getDynamic(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, ref) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Offer/GetPrepareOfferDetailsBasedOnPrepareOrderId?Offerrefrencenumber=" + ref + "&EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function AddOfferAttachmentDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        body: formData,
    };
    return fetch(baseApiURL + "/Offer/UploadOfferMultiple?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getUploadedFiles(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, ref) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Offer/DownloadOfferFiles?EncdUserId=" + encodeURIComponent(userId) + "&offerReferenceNumber=" + ref, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function deleteOfferAttachments(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    //var formdata = {
    //    AttachmetId: data.attachmetId,
    //};
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        /*   body: JSON.stringify(data)*/
    };
    return fetch(baseApiURL + "/Offer/DeleteOfferAttachments?EncdUserId=" + encodeURIComponent(userId) + "&attachmentid=" + data, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function downloadACBReports(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, offerreferencenumber) {
    const options = {
        method: "post",
        url: baseApiURL + "/Offer/OfferReportsDownload?EncdUserId=" + encodeURIComponent(userId) + "&offerreferencenumber=" + offerreferencenumber,
        responseType: "arraybuffer",
        headers: {


            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

    };
    return axios(options)
        .then((response) => {
            return response;
            console.log(response);
        })
        .catch((error) => {
            return error;
        });

}

export function SendOfferEmailToCustomer(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formdata) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata),
    };
    return fetch(baseApiURL + "/Offer/SendOfferEmail?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
