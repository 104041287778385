import { baseApiURL } from "../Utilities/Utility";
export function getShort(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        //mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetShorttimedelaytsdMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getItripping(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        //mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetItrippingiiMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getNtripping(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        // mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetNtrippinginMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getGct(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        // mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetGctMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getGtripping(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        //mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetGtrippingIgMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getGalarm(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetGalarmIgMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getTimeDelay(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetTimeDelayTgTripMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getEtuStatus(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetEtuStatusMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getCtTest(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetCtTestMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getTripUnit(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetTripUnitMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getFunctionTest(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetFunctionTestMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getContactErosion(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetContactErosionIndicatorMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getArc(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetArcChutesMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getLamination(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetLaminationContactsMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getGuideFrame(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetGuideFrameTerminalsMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getContactPressure(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetContactPressureMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getPoleSet(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetPoleSetMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getLubrication(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },


    };
    return fetch(baseApiURL + "/Common/GetLubricationMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getConfirmation(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetConfirmationMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getCoil(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetCoilMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getVoltage(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetUnderVoltageMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getSpring(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetSpringChargingMotorMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getReady(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetReadyToCloseInterlockMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getBreaker(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetBreakerOperationsMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getTime(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        //mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetLtimelagtrMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getService(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetServiceMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getMech(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetMechanicalInterlockMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getSub(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {

    const options = {

        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },


        body: JSON.stringify(data),

    };
    return fetch(baseApiURL + "/Quotation/PostQuotationData?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getMemory(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        //mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetMemoryMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getModel(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        //mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetModelMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getInt(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        // mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetIn1Master?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAux(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetAuxiliaryContactBlockMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getProduct(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetProductTypeMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getOrder(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetOrderTypeMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getSector(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetSectorMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getYear(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetUniqueSerialNoYearMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteView(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, QuotationId) {
    var formdata = {
        QuotationId: QuotationId,
        EncdUserId: encodeURIComponent(userId)
    };
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata)
    };
    return fetch(baseApiURL + "/Quotation/DeleteQuotationDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllUser(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetAllUserDetails?EncdUserId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllValue(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetAllValueMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllRegion(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetAllRegionMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllOffice(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetAllOfficeMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllRoll(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetAllRollMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function SaveUser(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,

    formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Common/PostUserDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function UpdateUser(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Common/UpdateUserDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function DeleteUser(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Common/DeleteUserDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllRole(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },


    };
    return fetch(baseApiURL + "/Common/GetAllRoles?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getAllRoles(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetLandingMenuData?userId=" + userId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//terms and condition && notes api binding
export function getTermsConditions(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, offertypeid) {
    const options = {
        method: "get",

        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

        params: { EncdUserId: userId, offertypeid: offertypeid }
    };
    return fetch(baseApiURL + "/Offer/GetTermsAndConditions?EncdUserId=" + encodeURIComponent(userId) + "&offertypeid=" + offertypeid, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getNotes(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, offertypeid) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        params: { EncdUserId: userId, offertypeid: offertypeid }


    };
    return fetch(baseApiURL + "/Offer/GetNotes?EncdUserId=" + encodeURIComponent(userId) + "&offertypeid=" + offertypeid, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


export function getWorkScope(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, offertypeid) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        params: { EncdUserId: userId, offertypeid: offertypeid }


    };
    return fetch(baseApiURL + "/Offer/GetScopeOfWork?EncdUserId=" + encodeURIComponent(userId) + "&offertypeid=" + offertypeid, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//PostTermsAndConditions

export function termsandConditionsPost(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",

        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/PostTermsAndConditions?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


export function notesPost(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",

        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/PostNotes?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function workScopePost(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",

        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)

    };
    return fetch(baseApiURL + "/Offer/PostScopeOfWork?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//UploadTermsAndConditions

export function uploadTNCDoc(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, filechosen) {
    const options = {
        method: "post",
        headers: {


            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: filechosen,

        // body: JSON.stringify(data)

    };
    return fetch(baseApiURL + "/Offer/UploadTermsAndConditions?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//GetTermsAndConditionsAttachments
export function getTermsAndConditionAttchData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },


    };
    return fetch(baseApiURL + "/Offer/GetTermsAndConditionsAttachments?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//DeleteCustomerDetails

export function deleteCustomerDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formdata) {
    //var formdata = {
    //    QuotationId: QuotationId,
    //    EncdUserId: encodeURIComponent(userId)
    //};
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata)
    };
    return fetch(baseApiURL + "/Customer/DeleteCustomerDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//UploadItemDetails
export function uploadItemChoosen(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {


            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData,

        // body: JSON.stringify(data)

    };
    return fetch(baseApiURL + "/Offer/UploadItemDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function uploadOffer(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {


            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData,

        // body: JSON.stringify(data)

    };
    return fetch(baseApiURL + "/Offer/UploadOfferReport?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//DownloadReport
export function getDownloadReport(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    ref
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        params: { EncdUserId: userId, offerReferenceNumber: ref }



    };
    return fetch(baseApiURL + "/Offer/DownloadReport?EncdUserId=" + encodeURIComponent(userId) + "&offerReferenceNumber=" + ref, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function geAllTypeReports(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    ref
) {
    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        params: { EncdUserId: userId, offerReferenceNumber: ref }



    };
    return fetch(baseApiURL + "/Offer/OfferReportsDownload?EncdUserId=" + encodeURIComponent(userId) + "&offerReferenceNumber=" + ref, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}



